@import "hacker";

header { background: rgba(0, 0, 0, 0.1); border-bottom: 1px dashed #b5e853; padding: 5px 0; margin: 0 0 0 0; }

header h1 {
  font-size: 20px;
  margin: 0 0 0 0;
}

header h1:before {
  content: none;
}

#main_content {
  margin: 20px 0 0 0;
}

.page_title {
  margin: 0 0 0px;
}

